import { CompanyType } from 'app/shared/models';

export interface ContentVariable {
  translationKey?: string;
  key: string;
  context?: ContentContext;
}

export type ContentContext = 'Question' | 'Communication' | 'none';

export const variableMap: Record<CompanyType, ContentVariable[]> = {
  'CX': [
    { key: 'organisation.name', translationKey: 'INSIGHT.SURVEY.HELPTEXTS.ORGANISATION_NAME_CX', context: 'Question' },
    { key: 'expectedTimeMinutes', context: 'Communication' },
    { key: 'surveyEndDate', context: 'Communication' },
    { key: 'company.ceoName', context: 'Communication' },
  ],
  'EX': [
    { key: 'group.manager.fullName', context: 'Question' },
    { key: 'organisation.name', context: 'Question' },
    { key: 'group.name', context: 'Question' },
    { key: 'expectedTimeMinutes', context: 'Communication' },
    { key: 'surveyEndDate', context: 'Communication' },
    { key: 'company.hrContact', context: 'Communication' },
    { key: 'company.ceoName', context: 'Communication' },
  ]
};
